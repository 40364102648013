/**
 * @flow
 * @relayHash 0317c7764a2eaf4597fc4886eed3f68a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type WithheldCashFormBase_viewer$ref = any;
export type WithheldCashFormBaseQueryVariables = {|
  accountId?: ?string
|};
export type WithheldCashFormBaseQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: WithheldCashFormBase_viewer$ref
  |}
|};
export type WithheldCashFormBaseQuery = {|
  variables: WithheldCashFormBaseQueryVariables,
  response: WithheldCashFormBaseQueryResponse,
|};
*/


/*
query WithheldCashFormBaseQuery(
  $accountId: ID
) {
  viewer {
    ...WithheldCashFormBase_viewer_shlXP
    id
  }
}

fragment WithheldCashFormBase_viewer_shlXP on Query {
  cashWithheldSet(accountId: $accountId, orderBy: "-createdAt") {
    edges {
      node {
        id
        currency
        reason
        amount
        status
        createdAt
        updatedAt
        notes {
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "accountId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "accountId",
  "variableName": "accountId"
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "WithheldCashFormBaseQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "WithheldCashFormBase_viewer",
            "args": [
              (v1/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "WithheldCashFormBaseQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "cashWithheldSet",
            "storageKey": null,
            "args": [
              (v1/*: any*/),
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "-createdAt"
              }
            ],
            "concreteType": "CashWithheldNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "CashWithheldNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CashWithheldNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "currency",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "reason",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "amount",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "status",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "updatedAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "notes",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "NotesNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "WithheldCashFormBaseQuery",
    "id": null,
    "text": "query WithheldCashFormBaseQuery(\n  $accountId: ID\n) {\n  viewer {\n    ...WithheldCashFormBase_viewer_shlXP\n    id\n  }\n}\n\nfragment WithheldCashFormBase_viewer_shlXP on Query {\n  cashWithheldSet(accountId: $accountId, orderBy: \"-createdAt\") {\n    edges {\n      node {\n        id\n        currency\n        reason\n        amount\n        status\n        createdAt\n        updatedAt\n        notes {\n          id\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4872edb7119a0aaec0499d79b4d82578';
module.exports = node;
