/**
 * @flow
 * @relayHash eb24ecfde55fb9f5ba0183bc09e94fc4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CashWithheldStatus = "RE" | "WH" | "%future added value";
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
export type WithholdCashMutationVariables = {|
  accountId: string,
  amount: number,
  currency?: ?Currency,
  reason: string,
  ignoreCashValidation?: ?boolean,
|};
export type WithholdCashMutationResponse = {|
  +withholdCash: ?{|
    +cashWithheld: ?{|
      +id: string,
      +amount: number,
      +createdAt: any,
      +updatedAt: any,
      +currency: Currency,
      +status: CashWithheldStatus,
      +reason: string,
      +notes: ?{|
        +id: string
      |},
    |}
  |}
|};
export type WithholdCashMutation = {|
  variables: WithholdCashMutationVariables,
  response: WithholdCashMutationResponse,
|};
*/


/*
mutation WithholdCashMutation(
  $accountId: ID!
  $amount: Float!
  $currency: Currency
  $reason: String!
  $ignoreCashValidation: Boolean
) {
  withholdCash(accountId: $accountId, amount: $amount, currency: $currency, reason: $reason, ignoreCashValidation: $ignoreCashValidation) {
    cashWithheld {
      id
      amount
      createdAt
      updatedAt
      currency
      status
      reason
      notes {
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "accountId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "amount",
    "type": "Float!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "currency",
    "type": "Currency",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "reason",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "ignoreCashValidation",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "withholdCash",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "accountId",
        "variableName": "accountId"
      },
      {
        "kind": "Variable",
        "name": "amount",
        "variableName": "amount"
      },
      {
        "kind": "Variable",
        "name": "currency",
        "variableName": "currency"
      },
      {
        "kind": "Variable",
        "name": "ignoreCashValidation",
        "variableName": "ignoreCashValidation"
      },
      {
        "kind": "Variable",
        "name": "reason",
        "variableName": "reason"
      }
    ],
    "concreteType": "WithholdCash",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "cashWithheld",
        "storageKey": null,
        "args": null,
        "concreteType": "CashWithheldNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "amount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "createdAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "updatedAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "currency",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "reason",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "notes",
            "storageKey": null,
            "args": null,
            "concreteType": "NotesNode",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "WithholdCashMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "WithholdCashMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "WithholdCashMutation",
    "id": null,
    "text": "mutation WithholdCashMutation(\n  $accountId: ID!\n  $amount: Float!\n  $currency: Currency\n  $reason: String!\n  $ignoreCashValidation: Boolean\n) {\n  withholdCash(accountId: $accountId, amount: $amount, currency: $currency, reason: $reason, ignoreCashValidation: $ignoreCashValidation) {\n    cashWithheld {\n      id\n      amount\n      createdAt\n      updatedAt\n      currency\n      status\n      reason\n      notes {\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cd48c1b7270e25dbf5fa09a058cb9039';
module.exports = node;
