import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import Grid from '@material-ui/core/Grid';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Dialog from '@material-ui/core/Dialog';
import { graphql } from 'react-relay';
import { Drawer } from '@material-ui/core';
import Price from '../../common/Price';
import TableButton from '../../common/buttons/TableButton';
import TitledWidgetItem from '../../common/TitledWidgetItem';
import VerticalTable from '../../common/VerticalTable';
import TableStatus from '../../common/TableStatus';
import ActionOnlyModalDlgForm from '../../common/dialogs/ActionOnlyModalDlgForm';
import { doCommitPromise } from '../../../common/commit';
import setInterestPaymentsMutation from '../../../mutations/SetInterestPayments';
import { useCommonStyles } from '../../common/Styles';
import AdminMetaContext from '../../adminPanel/AdminMetaContext';
import MoreButton from '../../common/buttons/MoreButton';
import MultiCurrencyValue from '../../common/MultiCurrencyValue';
import WithheldCashFormBase from './WithheldCashFormBase';

export default function CashBalances(props) {
  const {
    initiateConversion,
    initiateTransfer,
    account,
    onUpdate,
  } = props;

  const { cashwithheldSet: cashWithheldSet } = account;

  const cashBalances = {};
  account.actualCashBalances.forEach((balance) => {
    cashBalances[balance.currency] = [balance.balance, 0.0];
  });

  account.availableCashBalances.forEach((balance) => {
    if (!(balance.currency in cashBalances)) {
      cashBalances[balance.currency] = [0.0, balance.balance];
    }
    cashBalances[balance.currency][1] = balance.balance;
  });

  const cashBalancesArray = [];
  Object.keys(cashBalances).forEach((balance) => {
    cashBalancesArray.push([
      balance,
      cashBalances[balance][0],
      cashBalances[balance][1],
    ]);
  });
  cashBalancesArray.sort((a, b) => {
    if (a < b) {
      return -1;
    }
    return 1;
  });


  const { clientaccount: clientAccount } = account;
  const adminMeta = React.useContext(AdminMetaContext);
  const classes = useCommonStyles();
  const [showSetAccruesInterest, setShowSetAccruesInterest] = useState(false);
  const [showWithheldForm, setShowWithheldForm] = useState(false);


  const headColumns = [
    ['Withheld', node => (
      <Grid
        container
        justifyContent="flex-end"
        spacing={1}
        alignItems="baseline"
      >
        <Grid item>
          <MultiCurrencyValue
            textVariant={null}
            valueNode={
              {
                edges: cashWithheldSet.edges.filter(y => y.node.status === 'WH').map(x => ({
                  node: {
                    currency: x.node.currency,
                    quantity: x.node.amount,
                  },
                })),
              }
            }
          />
        </Grid>
        <Grid item>
          <MoreButton onClick={() => setShowWithheldForm(true)} />
        </Grid>
      </Grid>
    )],
  ];

  if (clientAccount) {
    headColumns.push(['Accrues Interest', node => (
      <Grid
        container
        justifyContent="flex-end"
        spacing={1}
        alignItems="baseline"
      >
        <Grid item>
          <TableStatus
            {...(node.accruesInterest ? ({ color: 'primary' }) : ({}))}
          >
            {node.accruesInterest ? 'Enabled' : 'Disabled'}
          </TableStatus>
        </Grid>
        <Grid item>
          <TableButton
            disabled={node.accountStatus !== 'ACCOUNT_APPROVED'}
            onClick={() => setShowSetAccruesInterest(true)}
          >
            Change
          </TableButton>
        </Grid>
      </Grid>
    )]);
  }


  return (
    <TitledWidgetItem
      title="Cash Balances"
      xs={12}
    >
      {
        clientAccount && (
          <Dialog
            open={showSetAccruesInterest}
            onClose={() => setShowSetAccruesInterest(false)}
            aria-labelledby="form-dialog-title"
            className={classes.dialogSingleInput}
            maxWidth="sm"
            fullWidth
          >
            <ActionOnlyModalDlgForm
              fieldName="Username"
              title="Change Interst Accrual Settings"
              buttonOptions={[
                {
                  color: 'primary',
                  label: account.clientaccount.accruesInterest ? 'Disable' : 'Enable',
                  onClickPromise: () => doCommitPromise(
                    setInterestPaymentsMutation,
                    {
                      clientAccountId: account.clientaccount.id,
                      enable: !account.clientaccount.accruesInterest,
                    },
                    () => setShowSetAccruesInterest(false),
                    () => null,
                  ),
                },
              ]}
              onClose={() => setShowSetAccruesInterest(false)}
            />
          </Dialog>
        )
      }

      <Drawer
        anchor="right"
        open={showWithheldForm}
        onClose={() => setShowWithheldForm(false)}
      >

        <div className={classes.drawerRootExtended}>
          <WithheldCashFormBase
            accountId={account.id}
            onUpdate={onUpdate}
          />
        </div>
      </Drawer>


      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Currency</TableCell>
                <TableCell align="right">Actual</TableCell>
                <TableCell align="right">Available</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {
                cashBalancesArray.map(
                  balance => (
                    <TableRow key={balance[0]}>
                      <TableCell align="left">{balance[0]}</TableCell>
                      <TableCell align="right">
                        <Price currency={balance[0]}>{balance[1]}</Price>
                      </TableCell>
                      <TableCell align="right">
                        <Price currency={balance[0]}>{balance[2]}</Price>
                      </TableCell>
                      <TableCell align="center">
                        <Grid container spacing={1} justify="center">
                          {balance[0] !== adminMeta.baseCurrency && balance[1] !== 0 && (
                            <Grid item>
                              <TableButton
                                key={balance[0]}
                                color="primary"
                                onClick={() => initiateConversion(balance[0], balance[1])}
                              >
                                CONVERT
                              </TableButton>
                            </Grid>
                          )}

                          {balance[2] >= 0 && (
                            <Grid item>
                              <TableButton
                                key={balance[0]}
                                color="secondary"
                                onClick={() => initiateTransfer(balance[0], balance[1])}
                              >
                                Transfer
                              </TableButton>
                            </Grid>
                          )}

                        </Grid>
                      </TableCell>
                    </TableRow>
                  ),
                )
              }
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12}>
          <VerticalTable
            showHeadings={false}
            columnMeta={headColumns}
            dataNode={account.clientaccount}
          />
        </Grid>

      </Grid>

    </TitledWidgetItem>
  );
}

graphql`
  fragment CashBalances_account on AccountNode {

    actualCashBalances {
      currency
      balance
    }

    availableCashBalances {
      currency
      balance
    }

    cashwithheldSet {
      edges {
        node {
          id
          amount
          currency
          status
          reason
          createdAt
          updatedAt
        }
      }
    }
    
  }
`;
