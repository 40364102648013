import { graphql } from 'react-relay';

const releaseWithheldCashMutation = graphql`
  mutation ReleaseWithheldCashMutation (
    $withheldCashId: ID!
  ) {
    releaseWithheldCash (
      withheldCashId: $withheldCashId
    ) {
      cashWithheld {
        id
        amount
        createdAt
        updatedAt
        currency
        status
        
        notes {
          id
        }
        
      }
    }
  }
`;

export default releaseWithheldCashMutation;
