/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type CashWithheldStatus = "RE" | "WH" | "%future added value";
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type CashBalances_account$ref: FragmentReference;
declare export opaque type CashBalances_account$fragmentType: CashBalances_account$ref;
export type CashBalances_account = {|
  +actualCashBalances: ?$ReadOnlyArray<?{|
    +currency: ?Currency,
    +balance: ?number,
  |}>,
  +availableCashBalances: ?$ReadOnlyArray<?{|
    +currency: ?Currency,
    +balance: ?number,
  |}>,
  +cashwithheldSet: {|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +amount: number,
        +currency: Currency,
        +status: CashWithheldStatus,
        +reason: string,
        +createdAt: any,
        +updatedAt: any,
      |}
    |}>
  |},
  +$refType: CashBalances_account$ref,
|};
export type CashBalances_account$data = CashBalances_account;
export type CashBalances_account$key = {
  +$data?: CashBalances_account$data,
  +$fragmentRefs: CashBalances_account$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currency",
  "args": null,
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "balance",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "CashBalances_account",
  "type": "AccountNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "actualCashBalances",
      "storageKey": null,
      "args": null,
      "concreteType": "MultiCurrencyNode",
      "plural": true,
      "selections": (v1/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "availableCashBalances",
      "storageKey": null,
      "args": null,
      "concreteType": "MultiCurrencyNode",
      "plural": true,
      "selections": (v1/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "cashwithheldSet",
      "storageKey": null,
      "args": null,
      "concreteType": "CashWithheldNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CashWithheldNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CashWithheldNode",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "id",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "amount",
                  "args": null,
                  "storageKey": null
                },
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "status",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "reason",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "createdAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "updatedAt",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b4bd7f2d040fc4ed158b158e9866dd7b';
module.exports = node;
