import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { createRefetchContainer, graphql } from 'react-relay';
import DrawerGridItemHeading from '../../common/drawer/DrawerGridItemHeading';
import DrawerGridItemContainer from '../../common/drawer/DrawerGridItemContainer';
import DrawerGridItemSelectField from '../../common/drawer/DrawerGridItemSelectField';
import DrawerGridItemTextField from '../../common/drawer/DrawerGridItemTextField';
import DrawerGridItemNumberField from '../../common/drawer/DrawerGridItemNumberField';
import AdminMetaContext from '../../adminPanel/AdminMetaContext';
import DrawerGridItemBottomButtonContainer from '../../common/drawer/DrawerGridItemBottomButtonContainer';
import { AddButton } from '../../common/buttons/BaseButton';
import { doCommitPromise } from '../../../common/commit';
import withholdCashMutation from '../../../mutations/WithholdCash';
import { createQueryRendererProgressV2 } from '../../common/QueryRendererProgressV2';
import DataTableWidget from '../../common/DataTableWidget';
import TableFieldMeta from '../../../common/TableFieldMeta';
import { isRecurNull } from '../../../common/helpers';
import CashWithheldStatus from './CashWithheldStatus';
import notiStack from '../../../common/notiStack';


function WithheldCashFormBase(props) {
  const {
    accountId,
    relay,
    filter,
    viewer,
    onUpdate,
  } = props;
  filter.setRelay(relay);
  const cashWithheldSet = isRecurNull(viewer, ['cashWithheldSet', 'edges']);
  const [members] = useState({
    amount: 0,
    reason: null,
  });

  const adminMeta = React.useContext(AdminMetaContext);
  const [currency, setCurrency] = useState(adminMeta.baseCurrency);
  return (
    <>
      <DrawerGridItemContainer>
        <DrawerGridItemHeading
          heading="Cash Withheld"
        />
        <DrawerGridItemSelectField
          label="Currency"
          enumType="Currency"
          xs={3}
          value={currency}
          onChange={e => setCurrency(e.target.value)}
        />
        <DrawerGridItemNumberField
          xs={3}
          label="Amount"
          helperText="Amount to withhold"
          onChange={(e) => {
            members.amount = parseFloat(e.target.value);
          }}
        />
        <DrawerGridItemTextField
          xs={6}
          label="Reason"
          helperText="Reason that appears in activity"
          onChange={(e) => {
            members.reason = e.target.value;
          }}
        />
        <DrawerGridItemBottomButtonContainer>
          <Grid item>
            <AddButton
              onClickPromise={() => {
                if (!members.reason) {
                  notiStack.warning('Reason not specified');
                  return null;
                }
                return doCommitPromise(
                  withholdCashMutation,
                  {
                    accountId,
                    currency,
                    amount: -members.amount,
                    reason: members.reason,
                    ignoreCashValidation: true,
                  },
                  () => {
                    filter.update();
                    onUpdate && onUpdate();
                  },
                  () => null,
                );
              }}
            />
          </Grid>
        </DrawerGridItemBottomButtonContainer>

        <DataTableWidget
          xs={12}
          title="Withheld Cash"
          columnMeta={[
            TableFieldMeta.localId,
            TableFieldMeta.createdAt,
            TableFieldMeta.priceField('amount', 'Amount', 'amount', 'currency'),
            TableFieldMeta.field('reason', 'Reason'),
            // TableFieldMeta.field('accountNumber', 'Acc No.  '),
            TableFieldMeta.fieldCell('status', '', row => (
              <CashWithheldStatus cashWithheld={row.value} onUpdate={() => filter.update()} />
            )),
            TableFieldMeta.notes(row => row.value),
          ]}
          dataNodes={(cashWithheldSet && cashWithheldSet.map(x => x.node)) || []}
        />

      </DrawerGridItemContainer>
    </>
  );
}

const query = graphql`
  query WithheldCashFormBaseQuery (
    $accountId: ID,
  ) {
    viewer {
      ...WithheldCashFormBase_viewer
      @arguments (
        accountId: $accountId
      )
    }
  }
`;

const container = createRefetchContainer(
  WithheldCashFormBase,
  {
    viewer: graphql`
      fragment WithheldCashFormBase_viewer on Query
      @argumentDefinitions(
        accountId: {type: ID, defaultValue: null }
      ) {
        cashWithheldSet(
          accountId: $accountId
          orderBy: "-createdAt"
        ) {
          edges {
            node {
              id
              currency
              reason
              amount
              status
              createdAt
              updatedAt
              notes {
                id
              }
            }
          }
        }
      }
    `,
  },
  query,
);

export default createQueryRendererProgressV2(
  query,
  container,
  {},
  ({ accountId }) => ({ accountId }),
);
