import React from 'react';
import Grid from '@material-ui/core/Grid';
import TableStatus from '../../common/TableStatus';
import EnumValue from '../../common/EnumValue';
import TableButton from '../../common/buttons/TableButton';
import { doCommitPromise } from '../../../common/commit';
import releaseWithheldCashMutation from '../../../mutations/ReleaseWithheldCash';

const colors = {
  WH: { color: 'secondary' },
  RE: { color: 'primary' },
};

export default function CashWithheldStatus(props) {
  const { cashWithheld, onUpdate } = props;
  return (
    <Grid
      container
      spacing={1}
      alignItems="baseline"
      justify="center"
    >
      <Grid item>
        <TableStatus {...colors[cashWithheld.status]}>
          <EnumValue
            enumType="CashWithheldStatus"
            enumValue={cashWithheld.status}
          />
        </TableStatus>
      </Grid>

      {cashWithheld.status === 'WH' && (
        <Grid item>
          <TableButton
            color="primary"
            onClickPromise={() => doCommitPromise(
              releaseWithheldCashMutation,
              {
                withheldCashId: cashWithheld.id,
              },
              () => (onUpdate && onUpdate()),
              () => null,
            )}
          >
            Relelase
          </TableButton>
        </Grid>
      )}

    </Grid>
  );
}
