import React, { useState } from 'react';
import { Drawer } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { commitMutation, createRefetchContainer, graphql } from 'react-relay';
import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import TableFieldMeta, {
  priceFigureComp,
} from '../../common/TableFieldMeta';
import VerticalTable from '../common/VerticalTable';
import { useCommonStyles } from '../common/Styles';
import Price from '../common/Price';
import { fromGlobalId } from '../../helpers/ID';
import environment from '../../Environment';
import { BackButton, CloseButton } from '../common/buttons/BaseButton';
import ConfirmationDlg from '../common/dialogs/ConfirmationDlg';
import BottomGridContainer from '../common/containers/BottomGridContainer';
import closeAccount from '../../mutations/CloseAccount';
import reopenAccount from '../../mutations/ReopenAccount';
import AccountCashTransferForm from './AccountCashTransferForm';
import CashBalances from './accountProfile/CashBalances';
import { VerticalTableWidget } from '../common/VerticalTableWidget';
import ClientUserInfo from './accountProfile/ClientUserInfo';
import CurrencyConversionForm from './CurrencyConversionForm';
import TenantUserInfo from './accountProfile/TenantUserInfo';
import AdminMetaContext from '../adminPanel/AdminMetaContext';
import { doCommitPromise } from '../../common/commit';
import withSuspense from '../common/table/Suspention';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import { isRecurNull } from '../../common/helpers';

const BankAccounts = withSuspense(
  React.lazy(() => (process.env.DEPLOYMENT_CODE === 'AU'
    ? import('./accountProfile/au/BankAccounts')
    : import('./accountProfile/uk/BankAccounts'))),
);

const ClientAccountInfo = withSuspense(
  React.lazy(() => (process.env.DEPLOYMENT_CODE === 'AU'
    ? import('./accountProfile/ClientAccountInfo')
    : import('./accountProfile/uk/ClientAccountInfo'))),
);

let Flags = null;
if (process.env.DEPLOYMENT_CODE === 'UK') {
  Flags = withSuspense(React.lazy(() => import('./accountProfile/uk/Flags')));
}

const AccountProfile = (props) => {
  const {
    filter,
    relay,
    viewer,
  } = props;
  filter.setRelay(relay);
  const account = isRecurNull(viewer, ['accounts', 'edges', 0, 'node']);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [conversionCurrency, setConversionCurrency] = useState(false);
  const [conversionQuantity, setConversionQuantity] = useState(false);
  const [transferCurrency, setTransferCurrency] = useState(false);
  const [transferQuantity, setTransferQuantity] = useState(false);

  const [accountCloseConfirmDlgOpen, setAccountCloseConfirmDlgOpen] = useState(
    false,
  );
  const [cashTransferDrawerOpen, setCashTransferDrawerOpen] = useState(false);
  const initiateConversion = (currency, quantity) => {
    setConversionCurrency(currency);
    setConversionQuantity(quantity);
    setDrawerOpen(true);
  };

  const initiateTransfer = (currency, quantity) => {
    setTransferCurrency(currency);
    setTransferQuantity(quantity);
    setCashTransferDrawerOpen(true);
  };

  const reopen = () => {
    commitMutation(environment, {
      mutation: reopenAccount,
      variables: { accountId: account.id },
    });
  };

  const tenantAccountDetails = [
    ['Name', 'name'],
    ['Account Type', VerticalTable.enum('accountType', 'TenantAccountType')],
    ['Created At', TableFieldMeta.createdAt.accessor],
    ['Updated At', TableFieldMeta.updatedAt.accessor],
    [
      'Tenant',
      node => (
        <Link href={`/tenant/${fromGlobalId(node.tenant.id)[1]}`}>
          {node.tenant.name}
        </Link>
      ),
    ],
  ];

  const totalCashHoldings = account.stockValueBase + account.actualCashBase;

  const adminMeta = React.useContext(AdminMetaContext);

  const summaryColumns = [
    ['Total', () => <Price currency={adminMeta.baseCurrency}>{totalCashHoldings}</Price>],
    ['Stock Holdings', priceFigureComp(['account', 'stockValueBase'], () => adminMeta.baseCurrency)],
    ['Cash Holdings - Actual', priceFigureComp(['account', 'actualCashBase'], () => adminMeta.baseCurrency)],
    ['Cash Holdings - Available', priceFigureComp(['account', 'availableCashBase'], () => adminMeta.baseCurrency)],
  ];

  const classes = useCommonStyles();

  const renderAccountCloseConfirmationDlg = () => (
    <Dialog
      open={accountCloseConfirmDlgOpen}
      onClose={() => setAccountCloseConfirmDlgOpen(false)}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <ConfirmationDlg
        message="Are you sure you want to close the account"
        options={[{ id: 0, caption: 'Yes' }, { id: 1, caption: 'No' }]}
        onOption={(option) => {
          if (option === 0) {
            return doCommitPromise(
              closeAccount,
              {
                accountId: account.id,
              },
              () => setAccountCloseConfirmDlgOpen(false),
            );
          }
          setAccountCloseConfirmDlgOpen(false);
          return null;
        }}
      />
    </Dialog>
  );

  return (
    <div className={classes.innerTabRoot}>
      {/*Sweep account balance*/}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <CurrencyConversionForm
          accountId={account.id}
          currency={conversionCurrency}
          quantity={conversionQuantity}
          onConvertSuccess={() => setDrawerOpen(false)}
          //onAdd={this.onAdd}
        />
      </Drawer>

      {/* Cash Transfer Drawer */}
      <Drawer
        anchor="right"
        open={cashTransferDrawerOpen}
        onClose={() => setCashTransferDrawerOpen(false)}
      >
        <AccountCashTransferForm
          accountId={account.id}
          currency={transferCurrency}
          amount={transferQuantity}
          onClose={() => setCashTransferDrawerOpen(false)}
        />
      </Drawer>

      {renderAccountCloseConfirmationDlg()}

      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={3}>
            {account.tenantaccount && (
              <>
                <VerticalTableWidget
                  title="Tenant Account Details"
                  showHeadings={false}
                  columnMeta={tenantAccountDetails}
                  dataNode={account.tenantaccount}
                  xs={12}
                />
                <TenantUserInfo tenantAccount={account.tenantaccount} />
              </>
            )}
            {account.clientaccount && (
              <>
                <ClientAccountInfo key={account.updatedAt} account={account} />
                <ClientUserInfo clientAccount={account.clientaccount} />
                {Flags && <Flags account={account} />}
              </>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Grid container spacing={3}>
            <VerticalTableWidget
              xs={12}
              columnMeta={summaryColumns}
              dataNode={{
                account,
                adminMeta,
              }}
              title="Holding Summary"
              showHeadings={false}
            />

            <Grid item xs={12}>
              <CashBalances
                account={account}
                initiateConversion={initiateConversion}
                initiateTransfer={initiateTransfer}
                onUpdate={() => filter.update()}
              />
            </Grid>

            <Grid item xs={12}>
              <BankAccounts
                account={account}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {account.clientaccount && (
        <BottomGridContainer>
          <Grid item>
            <BackButton
              disabled={
                account.clientaccount
                && account.clientaccount.accountStatus !== 'ACCOUNT_CLOSED'
              }
              onClick={() => reopen()}
              buttonText="Re-open"
            />
          </Grid>
          <Grid item>
            <CloseButton
              disabled={
                account.clientaccount
                && account.clientaccount.accountStatus === 'ACCOUNT_CLOSED'
              }
              onClick={() => setAccountCloseConfirmDlgOpen(true)}
            />
          </Grid>
        </BottomGridContainer>
      )}
    </div>
  );
};

const query = graphql`
  query AccountProfileQuery($accountId: ID) {
    viewer {
      ...AccountProfile_viewer
      @arguments(accountId: $accountId)
    }
  }
`;

graphql`
  fragment AccountProfile_account on AccountNode {
    id
    name
    actualCashBase
    availableCashBase
    stockValueBase
    updatedAt

    notes {
      id
    }

    ...CashBalances_account  @relay(mask: false)
    ...BankAccounts_account  @relay(mask: false)

    holdings {
      portfolio {
        id
        trackingVue {
          name
        }
      }
      contract {
        id
        symbol
        name
      }
      quantity
    }

    clientaccount {
      id
      name
      accountStatus
      accountType
      createdAt
      updatedAt
      accruesInterest

      ...AccountProfile @relay(mask: false)

      feeTemplate {
        id
        name
      }

      accountPtr {
        id
        notes {
          id
        }
      }

      adviser {
        id
        email
        firstName
        lastName
        parentTenant {
          name
          id
        }
      }

      tenant {
        id
        name
      }

      referenceNumber
      userModels {
        edges {
          node {
            id
            username
            email
            firstName
            lastName
            username
          }
        }
      }
      activeEmailRecipients {
        id
      }

      tradingApplication {
        id
      }
    }

    tenantaccount {
      name
      accountType
      createdAt
      updatedAt
      tenant {
        id
        name
        email
        firstName
        lastName
        username
      }
    }
  }
`;

const container = createRefetchContainer(
  AccountProfile,
  {
    viewer: graphql`
      fragment AccountProfile_viewer on Query
      @argumentDefinitions(
        accountId: {type: ID, defaultValue: null }
      ){

        accounts(id: $accountId) {
          edges {
            node {
                ... AccountProfile_account  @relay(mask: false)
            }
          }
        }
      }
    `,
  },
  query,
);

export default createQueryRendererProgressV2(
  query,
  container,
  {},
  props => ({ accountId: props.accountId }),
);
