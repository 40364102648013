import { graphql } from 'react-relay';

const withholdCashMutation = graphql`
  mutation WithholdCashMutation (
    $accountId: ID! 
    $amount: Float! 
    $currency: Currency 
    $reason: String!
    $ignoreCashValidation: Boolean 
  ) {
    withholdCash (
      accountId: $accountId
      amount: $amount
      currency: $currency
      reason: $reason
      ignoreCashValidation: $ignoreCashValidation
    ) {
      cashWithheld {
        id
        amount
        createdAt
        updatedAt
        currency
        status
        reason
        
        notes {
          id
        }
      }
      
    }
  }
`;

export default withholdCashMutation;
