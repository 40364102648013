/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type CashWithheldStatus = "RE" | "WH" | "%future added value";
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type WithheldCashFormBase_viewer$ref: FragmentReference;
declare export opaque type WithheldCashFormBase_viewer$fragmentType: WithheldCashFormBase_viewer$ref;
export type WithheldCashFormBase_viewer = {|
  +cashWithheldSet: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +currency: Currency,
        +reason: string,
        +amount: number,
        +status: CashWithheldStatus,
        +createdAt: any,
        +updatedAt: any,
        +notes: ?{|
          +id: string
        |},
      |}
    |}>
  |},
  +$refType: WithheldCashFormBase_viewer$ref,
|};
export type WithheldCashFormBase_viewer$data = WithheldCashFormBase_viewer;
export type WithheldCashFormBase_viewer$key = {
  +$data?: WithheldCashFormBase_viewer$data,
  +$fragmentRefs: WithheldCashFormBase_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "WithheldCashFormBase_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "accountId",
      "type": "ID",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "cashWithheldSet",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "accountId",
          "variableName": "accountId"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "-createdAt"
        }
      ],
      "concreteType": "CashWithheldNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CashWithheldNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CashWithheldNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "currency",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "reason",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "amount",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "status",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "createdAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "updatedAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "notes",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "NotesNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '46187d6acd2eb0575e5228cd58f83ca1';
module.exports = node;
