/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type AccountType = "COMPANY" | "INDIVIDUAL" | "JOINT" | "SSD" | "TRUST" | "%future added value";
export type ActionType = "AA" | "AACK" | "AAD" | "ABF" | "AC" | "ACCL" | "ACH" | "ACR" | "ACRO" | "ADIE" | "AEC" | "AEIE" | "AFNC" | "AFNU" | "AIAD" | "AIAE" | "AIAN" | "AIAR" | "AIAS" | "AIAT" | "AIRR" | "AIRS" | "AIRT" | "AM" | "ANC" | "AR" | "ARE" | "AS" | "ATCH" | "AU" | "BAAD" | "BACR" | "BADI" | "BAIG" | "BAPE" | "BAPI" | "BAPR" | "BAUI" | "BAUP" | "BAVE" | "BCC" | "BCU" | "BTIG" | "BTUI" | "CAREMFT" | "CASC" | "CASETFT" | "CASU" | "CIC" | "CISC" | "CISS" | "CISU" | "CIT" | "CIU" | "CIX" | "CTAPR" | "CTBLK" | "CTCAA" | "CTCAN" | "CTREC" | "CTREQ" | "CTUBL" | "CTVFY" | "EEU" | "FIFSI" | "FIFSP" | "FSFSI" | "FSFSP" | "GCBFV" | "GCBRA" | "GCBRC" | "GCBRD" | "GCBRF" | "GCBRI" | "GCBRO" | "GCBRV" | "GCMAC" | "GCMBD" | "GCMCA" | "GCMCD" | "GCMCR" | "GCMEX" | "GCMFL" | "GCMGA" | "GCMRE" | "GCMRI" | "GCMRR" | "GCMRS" | "GCMSA" | "GCMSU" | "GCMTR" | "GCPCO" | "GCPCR" | "GCPFL" | "GCPPO" | "GCPSU" | "GCSAM" | "GCSCA" | "GCSCR" | "GCSDA" | "GCSFD" | "GCSFI" | "GCSPA" | "GCSPC" | "GCSPD" | "GCSPR" | "GCSRE" | "GCSSP" | "GCSSR" | "LLEDN" | "MACC" | "MATC" | "MATU" | "MLTC" | "MLTU" | "OAA" | "OAR" | "OC" | "OCC" | "OCD" | "OM" | "OMCLA" | "OMCLD" | "OMCLN" | "OMCLR" | "OMCNI" | "OMD" | "OMEXP" | "OMOVA" | "OMOVD" | "OMPR" | "OMQA" | "OMRBC" | "OP" | "OPCA" | "OPFC" | "OQ" | "OR" | "ORRCE" | "OTC" | "OX" | "RLWHCH" | "SC" | "SCR" | "SF" | "SR" | "SRA" | "SRF" | "SS" | "SU" | "TBF" | "TCH" | "TCR" | "TIGC" | "TIGD" | "TIGU" | "TISC" | "TISU" | "TPC" | "TPU" | "TRSC" | "TRSU" | "ULMVF" | "ULMVS" | "ULNS" | "ULSC" | "ULUV" | "UMA" | "UMAF" | "UMAP" | "UMAR" | "UMARP" | "UMCP" | "UMCR" | "UMD" | "UMRRC" | "UMURP" | "UUE" | "UUF" | "UUL" | "UUP" | "UUSA" | "UUSD" | "UUU" | "UVE" | "VSPA" | "VSPR" | "VSPU" | "WHCH" | "ZBTR" | "ZBTU" | "ZIR" | "ZITR" | "ZIU" | "ZPC" | "ZTP" | "ZTR" | "ZUC" | "ZUU" | "%future added value";
export type AdvancedOrderType = "BRA" | "NON" | "OCA" | "%future added value";
export type AmendStatus = "APL" | "FAI" | "LOG" | "REQ" | "%future added value";
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
export type FeeType = "ADE" | "ADF" | "ASB" | "AVH" | "BUF" | "CHI" | "CHIP" | "CHUP" | "COR" | "CRDT" | "FXB" | "GST" | "INT" | "MGN" | "MSB" | "PLF" | "PLT" | "PRO" | "RTA" | "RTP" | "STB" | "STBV" | "TAX" | "TRA" | "VHF" | "WDR" | "%future added value";
export type InstrumentType = "CASH" | "STK" | "WAR" | "%future added value";
export type OrderAlgo = "TWAP" | "VWAP" | "%future added value";
export type OrderApprovalStatus = "CA" | "CD" | "EX" | "IN" | "OA" | "OD" | "RR" | "SA" | "SFA" | "%future added value";
export type OrderProposalType = "BT" | "IN" | "PR" | "%future added value";
export type OrderStatus = "CAN" | "CRE" | "FIL" | "INI" | "INM" | "PEX" | "PFL" | "POS" | "QUE" | "REJ" | "%future added value";
export type OrderType = "LMT" | "MKT" | "SLMT" | "SMKT" | "TLMT" | "TMKT" | "%future added value";
export type PortfolioOrderStatus = "CAN" | "COM" | "CRE" | "FIL" | "PEX" | "PFL" | "PRO" | "REJ" | "%future added value";
export type ReportState = "ERR" | "FIN" | "REQ" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type StockOrderDetails_stockOrder$ref: FragmentReference;
declare export opaque type StockOrderDetails_stockOrder$fragmentType: StockOrderDetails_stockOrder$ref;
export type StockOrderDetails_stockOrder = {|
  +parentOrder: ?{|
    +id: string
  |},
  +id: string,
  +orderRef: ?string,
  +status: ?string,
  +pendingStatus: ?string,
  +price: ?number,
  +quantity: ?number,
  +cashQuantity: ?number,
  +side: ?string,
  +createdAt: any,
  +updatedAt: any,
  +orderType: OrderType,
  +quantityToDate: ?number,
  +matched: ?boolean,
  +blockedHoldings: ?number,
  +blockedProceeds: ?number,
  +blockedBaseCharges: ?number,
  +blockedLocalCharges: ?number,
  +customCommission: ?number,
  +advancedOrderType: AdvancedOrderType,
  +actionLogs: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +actionId: number,
        +actionType: ActionType,
        +actionTime: any,
        +actionDescription: string,
        +user: ?{|
          +fullName: ?string
        |},
      |}
    |}>
  |},
  +contract: {|
    +id: string,
    +currency: Currency,
    +symbol: string,
    +name: ?string,
    +industry: ?string,
    +ibConId: number,
    +instrumentType: InstrumentType,
    +exchange: {|
      +name: string,
      +symbol: string,
    |},
  |},
  +account: ?{|
    +id: string,
    +name: ?string,
    +clientaccount: ?{|
      +id: string,
      +accountType: ?AccountType,
    |},
  |},
  +portfolioOrder: {|
    +id: string
  |},
  +executionSet: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +transaction: ?{|
          +id: string
        |},
        +price: number,
        +proceeds: ?number,
        +executionDatetime: any,
        +quantity: number,
        +currency: Currency,
      |}
    |}>
  |},
  +proceedsToDate: ?number,
  +proceedsToDateBase: ?number,
  +brokerOrder: ?{|
    +id: string,
    +twsOrderId: ?number,
    +twsPermId: ?number,
    +cashQuantity: ?number,
    +quantityFilled: ?number,
    +quantityLeft: ?number,
    +stopPrice: ?number,
    +trailStopRatio: ?number,
    +trailStopOffset: ?number,
    +lastSyncTime: ?any,
    +status: OrderStatus,
    +orderRef: ?string,
    +ackedClientOrderId: ?string,
    +brokerSideOrderId: ?string,
    +ibExchange: ?{|
      +id: string,
      +symbol: string,
    |},
    +algoParams: ?{|
      +algo: OrderAlgo,
      +orderalgoparamstwap: ?{|
        +strategyType: string,
        +startTime: ?any,
        +endTime: ?any,
        +allowPastEndTime: boolean,
      |},
      +orderalgoparamsvwap: ?{|
        +id: string,
        +maxVolumeRatio: ?number,
        +startTime: ?any,
        +endTime: ?any,
        +allowPastEndTime: boolean,
        +neverTakeLiquidity: boolean,
        +speedup: ?boolean,
      |},
    |},
    +brokerorderamendmentSet: {|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +limitPrice: ?number,
          +previousLimitPrice: ?number,
          +quantity: number,
          +previousQuantity: number,
          +status: AmendStatus,
          +blockingRevised: boolean,
          +createdAt: any,
          +updatedAt: any,
        |}
      |}>
    |},
  |},
  +orderPtr: {|
    +orderapprovalcontractSet: {|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +approval: {|
            +id: string,
            +portfolioOrder: ?{|
              +id: string,
              +status: PortfolioOrderStatus,
            |},
            +createdAt: any,
            +updatedAt: any,
            +approvedAt: ?any,
            +notifiedAt: ?any,
            +remindedAt: ?any,
            +proposal: {|
              +id: string,
              +type: OrderProposalType,
            |},
            +approvedBy: ?{|
              +fullName: ?string
            |},
            +status: OrderApprovalStatus,
            +portfolio: {|
              +id: string,
              +trackingVueName: ?string,
              +account: {|
                +name: ?string,
                +id: string,
              |},
            |},
            +actionLogs: ?{|
              +edges: $ReadOnlyArray<?{|
                +node: ?{|
                  +id: string,
                  +actionDescription: string,
                  +actionId: number,
                  +actionTime: any,
                  +actionType: ActionType,
                  +user: ?{|
                    +id: string,
                    +fullName: ?string,
                  |},
                |}
              |}>
            |},
          |},
        |}
      |}>
    |}
  |},
  +tradeInvoice: ?{|
    +id: string,
    +pdfUrl: ?string,
    +pdf: ?string,
    +state: ReportState,
    +createdAt: any,
    +updatedAt: any,
    +portfolioOrder: ?{|
      +id: string
    |},
  |},
  +transactions: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +commission: ?number,
        +tax: ?number,
        +transactionaccountchargeSet: {|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +amount: number,
              +currency: Currency,
              +feeType: FeeType,
              +accountchargePtr: {|
                +accountchargetenantportionSet: {|
                  +edges: $ReadOnlyArray<?{|
                    +node: ?{|
                      +id: string,
                      +quantity: number,
                      +tenant: ?{|
                        +name: string,
                        +id: string,
                      |},
                    |}
                  |}>
                |}
              |},
            |}
          |}>
        |},
      |}
    |}>
  |},
  +$refType: StockOrderDetails_stockOrder$ref,
|};
export type StockOrderDetails_stockOrder$data = StockOrderDetails_stockOrder;
export type StockOrderDetails_stockOrder$key = {
  +$data?: StockOrderDetails_stockOrder$data,
  +$fragmentRefs: StockOrderDetails_stockOrder$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "orderRef",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "price",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cashQuantity",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "actionId",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "actionType",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "actionTime",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "actionDescription",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v14 = [
  (v13/*: any*/)
],
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currency",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "symbol",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "portfolioOrder",
  "storageKey": null,
  "args": null,
  "concreteType": "PortfolioOrderNode",
  "plural": false,
  "selections": (v1/*: any*/)
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "startTime",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "endTime",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "allowPastEndTime",
  "args": null,
  "storageKey": null
},
v22 = [
  (v17/*: any*/),
  (v0/*: any*/)
];
return {
  "kind": "Fragment",
  "name": "StockOrderDetails_stockOrder",
  "type": "StockOrderNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "parentOrder",
      "storageKey": null,
      "args": null,
      "concreteType": "OrderNode",
      "plural": false,
      "selections": (v1/*: any*/)
    },
    (v0/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "pendingStatus",
      "args": null,
      "storageKey": null
    },
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "side",
      "args": null,
      "storageKey": null
    },
    (v7/*: any*/),
    (v8/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "orderType",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "quantityToDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "matched",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "blockedHoldings",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "blockedProceeds",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "blockedBaseCharges",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "blockedLocalCharges",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "customCommission",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "advancedOrderType",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "actionLogs",
      "storageKey": "actionLogs(orderBy:\"actionTime\")",
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "actionTime"
        }
      ],
      "concreteType": "ActionLogUserRequestNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ActionLogUserRequestNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ActionLogUserRequestNode",
              "plural": false,
              "selections": [
                (v9/*: any*/),
                (v10/*: any*/),
                (v11/*: any*/),
                (v12/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "user",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "UserNode",
                  "plural": false,
                  "selections": (v14/*: any*/)
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "contract",
      "storageKey": null,
      "args": null,
      "concreteType": "ContractNode",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v15/*: any*/),
        (v16/*: any*/),
        (v17/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "industry",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "ibConId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "instrumentType",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "exchange",
          "storageKey": null,
          "args": null,
          "concreteType": "ExchangeNode",
          "plural": false,
          "selections": [
            (v17/*: any*/),
            (v16/*: any*/)
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "account",
      "storageKey": null,
      "args": null,
      "concreteType": "AccountNode",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v17/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "clientaccount",
          "storageKey": null,
          "args": null,
          "concreteType": "ClientAccountNode",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "accountType",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    (v18/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "executionSet",
      "storageKey": null,
      "args": null,
      "concreteType": "ExecutionNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ExecutionNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ExecutionNode",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "transaction",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "TransactionNode",
                  "plural": false,
                  "selections": (v1/*: any*/)
                },
                (v4/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "proceeds",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "executionDatetime",
                  "args": null,
                  "storageKey": null
                },
                (v5/*: any*/),
                (v15/*: any*/)
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "proceedsToDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "proceedsToDateBase",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "brokerOrder",
      "storageKey": null,
      "args": null,
      "concreteType": "BrokerOrderNode",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "twsOrderId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "twsPermId",
          "args": null,
          "storageKey": null
        },
        (v6/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "quantityFilled",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "quantityLeft",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "stopPrice",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "trailStopRatio",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "trailStopOffset",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "lastSyncTime",
          "args": null,
          "storageKey": null
        },
        (v3/*: any*/),
        (v2/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "ackedClientOrderId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "brokerSideOrderId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "ibExchange",
          "storageKey": null,
          "args": null,
          "concreteType": "IBExchangeNode",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v16/*: any*/)
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "algoParams",
          "storageKey": null,
          "args": null,
          "concreteType": "OrderAlgoParamsNode",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "algo",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "orderalgoparamstwap",
              "storageKey": null,
              "args": null,
              "concreteType": "OrderAlgoParamsTWAPNode",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "strategyType",
                  "args": null,
                  "storageKey": null
                },
                (v19/*: any*/),
                (v20/*: any*/),
                (v21/*: any*/)
              ]
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "orderalgoparamsvwap",
              "storageKey": null,
              "args": null,
              "concreteType": "OrderAlgoParamsVWAPNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "maxVolumeRatio",
                  "args": null,
                  "storageKey": null
                },
                (v19/*: any*/),
                (v20/*: any*/),
                (v21/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "neverTakeLiquidity",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "speedup",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "brokerorderamendmentSet",
          "storageKey": "brokerorderamendmentSet(orderBy:\"sequence\")",
          "args": [
            {
              "kind": "Literal",
              "name": "orderBy",
              "value": "sequence"
            }
          ],
          "concreteType": "BrokerOrderAmendmentNodeConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "BrokerOrderAmendmentNodeEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "BrokerOrderAmendmentNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "limitPrice",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "previousLimitPrice",
                      "args": null,
                      "storageKey": null
                    },
                    (v5/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "previousQuantity",
                      "args": null,
                      "storageKey": null
                    },
                    (v3/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "blockingRevised",
                      "args": null,
                      "storageKey": null
                    },
                    (v7/*: any*/),
                    (v8/*: any*/)
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "orderPtr",
      "storageKey": null,
      "args": null,
      "concreteType": "OrderNode",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "orderapprovalcontractSet",
          "storageKey": null,
          "args": null,
          "concreteType": "OrderApprovalContractNodeConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "OrderApprovalContractNodeEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "OrderApprovalContractNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "approval",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "OrderApprovalNode",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "portfolioOrder",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "PortfolioOrderNode",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v3/*: any*/)
                          ]
                        },
                        (v7/*: any*/),
                        (v8/*: any*/),
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "approvedAt",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "notifiedAt",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "remindedAt",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "proposal",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "OrderProposalNode",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "type",
                              "args": null,
                              "storageKey": null
                            }
                          ]
                        },
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "approvedBy",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "UserNode",
                          "plural": false,
                          "selections": (v14/*: any*/)
                        },
                        (v3/*: any*/),
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "portfolio",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "PortfolioNode",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "trackingVueName",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "account",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "AccountNode",
                              "plural": false,
                              "selections": (v22/*: any*/)
                            }
                          ]
                        },
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "actionLogs",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "ActionLogUserRequestNodeConnection",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "edges",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "ActionLogUserRequestNodeEdge",
                              "plural": true,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "node",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "ActionLogUserRequestNode",
                                  "plural": false,
                                  "selections": [
                                    (v0/*: any*/),
                                    (v12/*: any*/),
                                    (v9/*: any*/),
                                    (v11/*: any*/),
                                    (v10/*: any*/),
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "user",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "UserNode",
                                      "plural": false,
                                      "selections": [
                                        (v0/*: any*/),
                                        (v13/*: any*/)
                                      ]
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "tradeInvoice",
      "storageKey": null,
      "args": null,
      "concreteType": "TradeInvoiceNode",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "pdfUrl",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "pdf",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "state",
          "args": null,
          "storageKey": null
        },
        (v7/*: any*/),
        (v8/*: any*/),
        (v18/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "transactions",
      "storageKey": null,
      "args": null,
      "concreteType": "TransactionNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "TransactionNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "TransactionNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "commission",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "tax",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "transactionaccountchargeSet",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "TransactionAccountChargeNodeConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "TransactionAccountChargeNodeEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "TransactionAccountChargeNode",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "amount",
                              "args": null,
                              "storageKey": null
                            },
                            (v15/*: any*/),
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "feeType",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "accountchargePtr",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "AccountChargeNode",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "accountchargetenantportionSet",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "AccountChargeTenantPortionNodeConnection",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "edges",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "AccountChargeTenantPortionNodeEdge",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "kind": "LinkedField",
                                          "alias": null,
                                          "name": "node",
                                          "storageKey": null,
                                          "args": null,
                                          "concreteType": "AccountChargeTenantPortionNode",
                                          "plural": false,
                                          "selections": [
                                            (v0/*: any*/),
                                            (v5/*: any*/),
                                            {
                                              "kind": "LinkedField",
                                              "alias": null,
                                              "name": "tenant",
                                              "storageKey": null,
                                              "args": null,
                                              "concreteType": "TenantNode",
                                              "plural": false,
                                              "selections": (v22/*: any*/)
                                            }
                                          ]
                                        }
                                      ]
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '7da8ee08bde72e4dd9e63c0883e5626b';
module.exports = node;
